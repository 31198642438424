import React from 'react';
import PastEventList from './PastEventList';

export default class AllPastEvents extends React.Component{
  render(){
    return(
      <div >
        <PastEventList/>
      </div> 
    )
  }
}