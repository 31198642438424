import React from 'react';
import EventList from './EventList';

export default class AllEvents extends React.Component{
  render(){
    return(
      <div >
        <EventList/>
      </div> 
    )
  }
}