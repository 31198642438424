import firebase from 'firebase';
import config from './firebaseConfig.json';

const firebaseConfig = {
    apiKey: config.apiKey,
    authDomain: config.authDomain,
    projectId: config.projectId,
    storageBucket: config.storageBucket,
    messagingSenderId: config.messagingSenderId,
    appId: config.appId,
    measurementId: config.measurementId
  };

const firebaseAppConfig =  firebase.initializeApp(firebaseConfig);
export default firebaseAppConfig;